.c-hamburger-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__bar {
    width: calc(100% - 25px);
    height: 2px;
    position: relative;
    background-color: white;
    border-radius: 2px;
    transition: all 0.3s;

    body.is-opened--drawer-menu & {
      background-color: transparent;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: white;
      border-radius: 2px;
      transition: all 0.3s;
    }

    &::before {
      top: -5px;

      body.is-opened--drawer-menu & {
        top: 0;
        transform: rotate(225deg);
      }
    }

    &::after {
      top: 5px;

      body.is-opened--drawer-menu & {
        top: 0;
        transform: rotate(-225deg);
      }
    }
  }
}
