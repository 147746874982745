/* =============================================
 * Gnav
 * ============================================= */

.l-gnav {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 42px;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &__inner {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $max-width;
    padding: 0 30px;

    @include media-breakpoint-down(sm) {
      height: 35px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
  }

  &__logo {
    width: 96px;
    display: flex;
    align-items: center;
    margin: 0;

    @include media-breakpoint-down(sm) {
      transform: translateY(2px);
    }

    @include media-breakpoint-up(md) {
      width: 116px;
    }

    &__img { }
  }

  &__list {
    width: 907px;
    display: flex;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__item {
    width: calc(100% / 6);
  }

  &__link {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    opacity: 1 !important;

    &::before {
      content: "";
      width: 1px;
      height: 26px;
      background-color: $border-gray-color;
      position: absolute;
      top: 50%;
      left: 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
    }
  }

  &__item.-active &__link,
  &__item:hover &__link {
    background-color: $theme-color;
    color: white;

    &::before {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__item.-top.-active + &__item.-company &__link::before,
  &__item.-company.-active + &__item.-contact &__link::before,
  &__item.-contact.-active + &__item.-privacy &__link::before,
  &__item.-privacy.-active + &__item.-public &__link::before,
  &__item.-public.-active + &__item.-en &__link::before,
  &__item.-top:hover + &__item.-company &__link::before,
  &__item.-company:hover + &__item.-contact &__link::before,
  &__item.-contact:hover + &__item.-privacy &__link::before,
  &__item.-privacy:hover + &__item.-public &__link::before,
  &__item.-public:hover + &__item.-en &__link::before {
    visibility: hidden;
    opacity: 0;
  }
}
