.c-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  tbody {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;
    border: solid 1px $border-light-gray-color;
    border-bottom: none;
  }

  &__tr {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__th,
  &__td {
    @include media-breakpoint-down(sm) {
      display: block;
      border-bottom: solid 1px $border-light-gray-color;
      padding: 7px 22px;
      word-break: break-word;
    }

    @include media-breakpoint-up(md) {
      border: solid 1px $border-light-gray-color;
      padding: 15px 29px;
    }
  }

  &__th {
    text-align: center;
    background-color: $bg-light-gray-color;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(md) {
      width: 236px;
    }
  }

  &__td {
  }
}
