/* =============================================
 * Base
 * ============================================= */

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-size: $base-sp-font-size;
  font-family: $base-font-family;
  line-height: $base-line-height;
  min-width: 320px;
  min-height: 100%;

  @include media-breakpoint-up(md) {
    min-width: $min-width;
    font-size: $base-font-size;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top;
  width: 100%;
}

a {
  color: $base-link-color;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.35s;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  img {
    border: none;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
    opacity: 0.85;
  }
}
