.c-drawer-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: black;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  padding-bottom: 20px;

  @include media-breakpoint-up(md) {
    display: none !important;
  }

  body.is-opened--drawer-menu & {
    opacity: 1;
    visibility: visible;
  }

  &__title {
    display: flex;
    height: 35px;
    align-items: center;
    font-size: 1.3rem;
    letter-spacing: -0.6px;
    color: white;
    padding-left: 31px;
    margin-bottom: 4px;

    @media screen and (max-width: 320px) {
      justify-content: center;
      padding-left: 0;
    }
  }

  &__list {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }

  &__item {

    &:not(:last-of-type) {
      border-bottom: solid 1px white;
    }
  }

  &__link {
    width: 100%;
    display: flex;
    align-items: center;
    height: 55px;
    position: relative;
    background-color: $drawer-menu-gray-color;
    font-size: 1.6rem;
    color: white !important;
    white-space: nowrap;
    padding-left: 31px;

    &::after {
      content: "";
      width: 10px;
      height: 13px;
      position: absolute;
      top: 50%;
      right: 25px;
      background: url("/assets/img/common/icon_arrow-right-white.svg") no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  &__site-link {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 170px;
    height: 35px;
    border: solid 1px white;
    color: white;
    white-space: nowrap;
  }
}
