.c-mainvis {
  width: 100%;
  position: relative;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 107px);
    min-height: 430px;
  }

  &__item {
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &.-no01 {
        background-image: url("/assets/img/top/kv01.png");
      }

      &.-no02 {
        background-image: url("/assets/img/top/kv02.jpg");
        align-items: flex-end;
      }

      &.-no03 {
        background-image: url("/assets/img/top/kv03.jpg");
      }

      &.-no04 {
        background-image: url("/assets/img/top/kv04.jpg");
        align-items: flex-end;
      }
    }
  }

  &__msg {
    width: 100%;
    max-width: $max-width;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.55;
    color: white;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      display: none !important;
    }

    &.-no02 {
      transform: translateY(-105px);
    }

    &.-no03 {
      transform: translateY(172px);
    }

    &.-no04 {
      display: flex;
      justify-content: flex-end;
      transform: translateY(-113px);
      padding-right: 76px;
    }
  }

  &__img {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__play-btn {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 50px;
    bottom: 60px;
    z-index: 11;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("/assets/img/common/icon_pause.svg");

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.-pause {
      background-image: url("/assets/img/common/icon_play.svg");
    }
  }

  .swiper-pagination-bullets {
    bottom: 50px !important;
  }

  .swiper-pagination-bullet {
    width: 80px;
    height: 4px;
    border-radius: inherit;
    background-color: white;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #666;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: none;
    margin-top: -26px;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .swiper-button-prev {
    left: 0;

    &::after {
      background-image: url("/assets/img/common/icon_prev.svg");
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0;

    &::after {
      background-image: url("/assets/img/common/icon_next.svg");
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .swiper-pagination {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
