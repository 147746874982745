/* =============================================
 * Header
 * ============================================= */

.l-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: black;
  z-index: 10;

  @include media-breakpoint-down(sm) {
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    //position: fixed;
  }

  &__inner {
    width: 100%;
    max-width: $max-width;
    padding: 0 10px 2px 30px;

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
  }

  &__top-group {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-top: 4px;
    }

    @include media-breakpoint-up(md) {
      height: 65px;
    }
  }

  &__logo {

    &__link {
      width: 94px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        width: 100px;
        height: 50px;
      }
    }

    &__img {

    }
  }

  &__en-link {
    font-size: 1.3rem;
    color: white !important;
    margin: 0 8px 0 auto;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__sony-site-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 173px;
    height: 36px;
    border: solid 1px white;
    font-size: 1.5rem;
    color: white !important;
    white-space: nowrap;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
