/* =============================================
 * Footer
 * ============================================= */

.l-footer {
  display: flex;
  justify-content: center;
  background-color: black;
  position: relative;

  &__inner {
    width: 100%;
    max-width: $max-width;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      padding: 19px 31px;
    }

    @include media-breakpoint-up(md) {
      height: 60px;
      display: flex;
      padding-top: 12px;
    }
  }

  &__copy {
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    padding-top: 5px;
    margin-left: auto;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      letter-spacing: 0.4px;
    }
  }
}
