.c-lower-mainvis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 158px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  @include media-breakpoint-up(md) {
    height: 400px;
    padding-top: 20px;
  }

  &.-company    {
    background-image: url("/assets/img/company/kv-sp.jpg");

    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/company/kv.jpg");
      padding-right: 7px;
    }
  }

  &__title {
    font-size: 2.4rem;
    color: white;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }
}
