.p-company {

  .l-main__inner {
    padding-top: 33px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 73px;
      padding-bottom: 160px;
    }
  }

  &__paragraph {
    line-height: 1.8;
    margin-bottom: 33px;

    @include media-breakpoint-up(md) {
      line-height: 2.1;
      margin-bottom: 33px;
    }
  }

  &__sec {

    &.-vision {
      margin-bottom: 39px;

      @include media-breakpoint-up(md) {
        margin-bottom: 84px;
      }

      .c-sec-title {
        margin-bottom: 31px;

        @include media-breakpoint-up(md) {
          margin-bottom: 41px;
        }
      }
    }

    &.-message {
      margin-bottom: 37px;

      @include media-breakpoint-up(md) {
        margin-bottom: 48px;
      }

      .c-sec-title {
        margin-bottom: 34px;

        @include media-breakpoint-up(md) {
          margin-bottom: 42px;
        }
      }
    }

    &.-info {
      margin-bottom: 46px;

      @include media-breakpoint-up(md) {
        margin-bottom: 48px;
      }

      .c-sec-title {
        margin-bottom: 42px;

        @include media-breakpoint-up(md) {
          margin-bottom: 42px;
        }
      }
    }

    &.-contact {
      margin-bottom: 48px;

      @include media-breakpoint-up(md) {
        margin-bottom: 48px;
      }

      .c-sec-title {
        margin-bottom: 42px;

        @include media-breakpoint-up(md) {
          margin-bottom: 42px;
        }
      }
    }
  }

  &__msg-photo {
    width: 100%;
    margin-bottom: 23px;

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      width: 237px;
      min-width: 237px;
      padding-top: 8px;
      margin-right: 57px;
    }

    &__img {
      margin-bottom: 6px;

      @include media-breakpoint-down(sm) {
        width: 44%;
        margin-right: 26px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 21px;
      }
    }
  }

  &__msg-sign {
    display: flex;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      align-items: center;
    }

    &__pos {
      margin-right: 20px;

      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
    }

    &__name {
      font-size: 2.2rem;

      @include media-breakpoint-down(sm) {
        line-height: 1;
      }
    }
  }

  &__msg-body {
    flex-grow: 1;
  }

  &__officer {
    display: flex;
    flex-wrap: wrap;

    &__dt {
      width: 138px;
      line-height: 2.1;
    }

    &__dd {
      width: calc(100% - 138px);
      line-height: 2.1;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 2.1rem;
    font-weight: bold;
    margin-bottom: 22px;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
      margin-bottom: 35px;
    }
  }

  &__btn-group {
    margin-bottom: 50px;

    .c-btn {
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
